section#contact {
  background-size: cover;
  background-color: unset;
  .headings {
    backdrop-filter: blur(3px);
  }
  h3,p {
    color: $white;
  }
}

