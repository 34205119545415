#mainNav {
  background-color: $white;
  &.navbar-shrink {
    background-color: $white;
  }
  .navbar-nav .nav-item .nav-link {
    color: $secondary;
  }
}

