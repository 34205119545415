header.masthead {
  background-position: right bottom;
  p {
    color: $white;
  }
  .intro-text {
    .intro-lead-in {
      font-style: normal;
      @include heading-font;
    }
    .intro-heading {
      font-size: 22px;
      font-weight: 400;
    }
  }
}

@media (min-width: 768px) {
  header.masthead {
    .intro-text {
      .intro-lead-in {
        font-style: normal;
        @include heading-font;
      }
      .intro-heading {
        font-size: 40px;
        font-weight: 400;
      }
    }
  }
}
